import React from 'react';
import { createStore } from 'redux';
import { Provider } from 'react-redux';

import rootReducer from './reducers';

function handleTrackedCtaClick(e) {
  if (
    typeof window !== 'undefined' &&
    window.dataLayer
  ) {
    const el = e.currentTarget;
    const section = el.getAttribute('data-section');
    window.dataLayer.push({
      event: 'select_content',
      select_contentdata: [
        {
          content_type: 'button',
          element: `${section ? `${section} :: ` : ''}${el.innerText}`,
          content_url: el.href || null,
          layer: location && location.pathname && location.pathname.substring(1).replace(/\//g, ' :: '),
        },
      ],
    });
  }
}

function initializeDataLayerCtaTracking() {
  window.onload = function () {
    const nonPromoLinks = document.querySelectorAll('a:not([class*="hasTrackedPromo"])');
    nonPromoLinks.forEach(link => {
      if (link) {
        link.addEventListener('mousedown', handleTrackedCtaClick, false);
      }
    });
  }
}

export const ReduxProvider = async () => {

  const awaitedRootReducer = await rootReducer

  return ({
    children,
    defaultLanguage,
    hasMulitLanguage,
    routeMappingObj,
    appConfiguration,
    appMode,
    appRegion,
    appEnv,
    errorUrl,
  }) => {
    const initialState = {
      routePathing: {
        defaultLang: defaultLanguage,
        hasMultiLang: hasMulitLanguage,
      },
      appRegion,
      appMode,
      appEnv,
      routePathMapping: routeMappingObj,
      Config: appConfiguration,
      errorUrl,
    };
  
    if (
      initialState.Config.market === 'mx'
    ) {
      initializeDataLayerCtaTracking();
    }
  
    const store = createStore(awaitedRootReducer, initialState);
  
    return <Provider store={store}>{children}</Provider>;
  };
}

export default ReduxProvider;
import React from 'react';
import { Router, browserHistory } from 'react-router';
import PropTypes from 'prop-types';

// @ts-ignore
import { ThemeProvider } from '@mmc/theme-provider';

import RouterRenderEngine from './router.engine';

const composeProviders = (Component, props) => {
  // make sure you pass all the props in
  return (
    <ThemeProvider>
      {(theme) => React.createElement(Component, { theme, ...props })}
    </ThemeProvider>
  );
};

const QueryStringToJSON = (queryString) => {
  return new Promise((resolve, reject) => {
    const pairs = queryString.slice(1).split('&');
    const result = {};
    pairs.forEach(function (pair) {
      const pairSplit = pair.split('=');
      result[pairSplit[0]] = decodeURIComponent(pairSplit[1] || '');
    });
  
    resolve(JSON.parse(JSON.stringify(result)));
  })
};

// const Provider: React.FC<{}> = ({ children }) => {
const Provider = ({ children }) => {
  return (
    <Router history={browserHistory} createElement={composeProviders}>
      {children}
    </Router>
  );
};

Provider.propTypes = {
  children: PropTypes.node.isRequired 
}

export default Provider;

export const newRouterRenderEngine = async (
  client,
  appRegion = 'language-masters',
  appLang = 'en',
  appMode = '',
) => {
  const queryParams = await QueryStringToJSON(window.location.search);
  return new Promise(async(resolve, reject) => {
    const routerEngine = new RouterRenderEngine(
      client,
      queryParams.resourcePath,
      appRegion,
      appLang,
      appMode,
    );
    const routes = await routerEngine.routeNodes;
    const defaultLanguage = await routerEngine.defaultLanguage;
    const hasMulitLanguage = await routerEngine.hasMulitLanguage;
    const {routeMappingObj} = routerEngine;
    const appConfiguration = await routerEngine.appConfiguration;
  
    resolve({ routes, defaultLanguage, hasMulitLanguage, routeMappingObj, appConfiguration });
  })
};

import ApolloClient from "apollo-client";
import { createPersistedQueryLink } from "apollo-link-persisted-queries";
import { createHttpLink } from "apollo-link-http";
import { IntrospectionFragmentMatcher, InMemoryCache, defaultDataIdFromObject } from "apollo-cache-inmemory";
import introspectionQueryResultData from './fragmentTypes.json';
import "isomorphic-fetch";

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

const stringToHash = (str) => str.split('').reduce((a, b)=> {
  a = ((a << 5) -a) + b.charCodeAt(0);
  return a & a
}, 0)

const createGqlCLient = (apolloState, GQLParams) => {
  const link = createPersistedQueryLink({ useGETForHashedQueries: true }).concat(createHttpLink({ uri: GQLParams.endpoint }));

  const client = new ApolloClient({
    ssrMode: true,
    link,
    cache: new InMemoryCache({
      fragmentMatcher,
      dataIdFromObject: object => {
        const type = object.__typename || object.resourceName;
        const id = object.id;
        if (type && id) {
          if (object.__typename === 'Vehicle' && object.cta) {
            return `${type}_${id}_navlink`;
          }
          if(object.__typename === 'SimpleDisclaimer' && object.id && object.detail){
            const detailHash = stringToHash(object.detail)
            return `${type}_${id}_${detailHash}`;
          }
          if(object.id && object.sectionHeading && object.id.includes('article:') ){
            const titleHash = stringToHash(`${object.sectionHeading}${object.id}`);
            return `${type}_${id}_${titleHash}`;
          }
          return `${type}_${id}`;
        } else {
          return defaultDataIdFromObject(object);
        }
      }
    }).restore(apolloState),
  });

  return client;
};


const graphqlClient = {
  server: GQLParams => {
    return createGqlCLient({}, GQLParams);
  },
  client: (apolloState, GQLParams) => {
    return createGqlCLient(apolloState, GQLParams);;
  }
};

export default graphqlClient;

export default async () => {
  // window data set by server/react-renderer/index - new ReduxRouterEngine
  return new Promise((resolve, reject) => {
    const { 
      appMode = window.__APP_MODE__, 
      GQLParams = window.__GQLCONSTANT__ 
    } = window.__PRELOADED_STATE__ || {};
  
    const {
      appRegion = window.__APP_REGION__ || '',
      appLang = window.__APP_LANG__ || '',
      env: appEnv = window.__APP_ENV__ || '',
      errorUrl = window.__ERROR_STATE__ || '',
    } = GQLParams || {};
  
    resolve({ appMode, GQLParams, appRegion, appLang, appEnv, errorUrl });
  })
};

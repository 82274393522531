export const FORM_QUERY_BLOCK = `Form {
    id
    resourceType
    lastModified
    leadFormType
    leadFormDescription
    leadOfferCode
    vehicleYear
    vehicleModel
    thankYouMessage {
      title
      text
      caption
      eyebrowText
    }
    confirmationPixel
    requiredMessage
    errorMessage
    buttonLabel
    CTAs {
      url
      label
      openNewTab
      priority
      icon
    }
    brochureFileDownloadLabel
    backgroundColour
    formAlignment
    registerAgainLabel
    validationMessageLabel
    buttonAlignment
    showRecaptcha
    recaptchaSiteKey
    formComponents {
      ... on FormCheckbox {
        resourceType
        checkboxLabel
        fieldLabel
        isRequired
        requiredMessage
        checkboxValue
        description
      }
      ... on DatePickerField {
        resourceType
        fieldLabel
        datePickerFieldName: fieldName
        constraints {
          constraintType
          regex
          validationMessage
          displayFormat
        }
        datePickerFieldRequired: isRequired
        requiredMessage
        helpMessage
        value
        inputType
        weekStartDay
        showWhenOptionMatches
      }
      ... on CarSelector {
        disclaimerData {
          id
          detail
        }
        addPriceDisclaimer
        priceDisclaimerId
        id
        resourceType
        lastModified
        componentLabel
        selectedLabel
        selectIcon {
          altText
          image
        }
        showSelection
        enableTwoColumns
        productTile {
          enableProductsRatingStars
          productSkuId
          parentProductSkuId
          code
          codeYear
          year
          price {
            value
            displayValue
            zeroMessage
          }
          vehicleImage {
            image
            altText
          }
          vehicleBackgroundImage {
            image
            altText
          }
          name
          cta {
            url
            label
          }
          buildLink {
            url
            label
          }
          tag {
            code
            label
          }
        }
      }
      ... on DealerLocator {
        resourceType
        disclaimerData {
          id
          detail
        }
        title
        headingTag
        subHeadline
        searchFieldLabel
        selectedLabel
        editLabel
        salesLabel
        servicesLabel
        saleOpeningHoursLabel
        serviceOpeningHoursLabel
        listLabel
        mapLabel
        searchHelpText
        locationCtaLabel
        viewMoreText
        preSelectedFilter
        searchRadius
        metricSystem
        showRating
        noGeolocationMessage
        noDealershipFoundMessage
        isRequired
        requiredMessage
        isFilterDealersBySelectedVehicle
        vehicleUnavailableErrorMessage
        advanceFilter
        limitReturningDealers
        allowMultiDealersSelectionforSubmitForm
        enableTheMap
        enableViewMap
        enableTheSearchPostcode
        constraints {
          constraintType
          regex
          validationMessage
          displayFormat
        }
        cta {
          url
          label
          priority
          icon
          openNewTab
        }
        initialDealers {
          name
          id         
          dealershipMarketId
          url
          phone {
            phoneType
            countryCode
            areaCode    
            phoneNumber          
            extensionNumber          
          }         
          address {         
            addressLine1         
            addressLine2         
            addressLine3         
            longitude         
            latitude         
            postalArea
            municipality         
            district         
            city         
          }         
          dealersDepartments {         
            name         
            code         
          }         
        }
      }
      ... on TextSectionBlock {
        resourceType
        disclaimerLabel
        useAccordion
        paragraph
        sectionHeading
        mainHeading
        theme
        textAlignment
        width
        headingTag
      }
      ... on Editorial {
        resourceType
        lastModified
        columns {
          image {
            image
            altText
          }
          description {
            mainHeading
            paragraph
          }
          selectButton
          link {
            url
            label
          }
          selectStyle
          downloadPDFText
          downloadPDFIcon
          displayVehiclePrice
        }
      }
      ... on BasicFormField {
        resourceType
        fieldName
        fieldLabel
        constraints {
          constraintType
          regex
          validationMessage
          displayFormat
        }
        isRequired
        requiredMessage
        helpMessage
        value
        ... on TextField {
          inputType
          previousLabel
          nextLabel
          weekStartDay
          maxLength
          showWhenOptionMatches
          constraintMessage
          readOnly
          defaultValue
        }
        ... on ChoiceType {
          options {
            key
            label
            active
            disabled
          }
          choiceFieldType
          fieldLongDescription {
            title
            text
          }
          placeholderText
          showWhenOptionMatches
        }
        ... on DatePickerField {
          inputType
          weekStartDay
          showWhenOptionMatches
        }
      }
      ... on Button {
        resourceType
        type
        name
        value
        label
      }
      ... on ColumnControl {
        id
        resourceType
        columns {
          components {
            ... on BasicFormField {
              resourceType
              fieldName
              fieldLabel
              constraints {
                constraintType
                regex
                validationMessage
                displayFormat
              }
              isRequired
              requiredMessage
              helpMessage
              value
              ... on TextField {
                inputType
                previousLabel
                nextLabel
                weekStartDay
                maxLength
                showWhenOptionMatches
                constraintMessage
                readOnly
                defaultValue
              }
              ... on ChoiceType {
                options {
                  key
                  label
                  active
                  disabled
                }
                choiceFieldType
                fieldLongDescription {
                  title
                  text
                }
                placeholderText
                showWhenOptionMatches
              }
              ... on DatePickerField {
                inputType
                weekStartDay
                showWhenOptionMatches
              }
            }
            ... on DatePickerField {
              resourceType
              fieldLabel
              datePickerFieldName: fieldName
              constraints {
                constraintType
                regex
                validationMessage
                displayFormat
              }
              datePickerFieldRequired: isRequired
              requiredMessage
              helpMessage
              value
              inputType
              weekStartDay
              showWhenOptionMatches
            }
            ... on TextSectionBlock {
              mainHeading
              paragraph
              resourceType
              headingTag
            }
            ... on Button {
              resourceType
              type
              name
              value
              label
            }
          }
        }
      }
    }
    steps {
      resourceType
      stepDescription {
        title
        text
      }
      buttonLabel
      isSubmissionStep
      components {
        ... on BasicFormField {
          resourceType
          fieldName
          fieldLabel
          constraints {
            constraintType
            regex
            validationMessage
          }
          isRequired
          requiredMessage
          helpMessage
          value
          ... on TextField {
            inputType
            previousLabel
            nextLabel
            weekStartDay
            maxLength
            showWhenOptionMatches
            constraintMessage
            readOnly
            defaultValue
          }
          ... on ChoiceType {
            options {
              key
              label
              active
              disabled
            }
            choiceFieldType
            fieldLongDescription {
              title
              text
            }
            placeholderText
            showWhenOptionMatches
          }
        }
        ... on Button {
          resourceType
          type
          name
          value
          label
        }
      }
    }
  }`
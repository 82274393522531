import { combineReducers } from 'redux';
const Configurator = import(/* webpackChunkName: "ConfiguratorReducers" */ "@mmc/configurator")
const TableCompareSection = import(/* webpackChunkName: "TableCompareSectionReducers" */ "@mmc/table-compare")
const CommonSetLocation = import(/* webpackChunkName: "CommonSetLocationReducers" */ "@mmc/common-set-location")
const VehicleComparisonTool = import(/* webpackChunkName: "VehiclesCompareSectionReducers" */ "@mmc/vehicle-comparison-tool")
const VehicleComparisonToolV2 = import(/* webpackChunkName: "VehiclesCompareSectionReducersV2" */ "@mmc/vehicle-comparison-tool-v2")
const FiftyFifty = import(/* webpackChunkName: "FiftyFiftyReducers" */ "@mmc/fifty-fifty")

const routePathing = (store, action) => {
  if (action.type === 'ROUTE_PATHING') {
    return {
      defaultLang: !store.defaultLang,
      hasMultiLang: !store.hasMultiLang,
    };
  }

  return store || { defaultLang: 'en', hasMultiLang: false };
};

const routeMapping = (store, action) => {
  if (action.type === 'ROUTE_PATHING_2') {
    return store;
  }

  return store || {};
};

const routePathMapping = (store, action) => {
  if (action.type === 'ROUTE_PATH_MAPPING') {
    return store;
  }

  return store || {};
};

const GQLParams = (store, action) => {
  if (action.type === 'GQL_PARAMS') {
    return store;
  }

  return store || {};
};

const appMode = (store, action) => {
  if (action.type === 'APP_MODE') {
    return store;
  }

  return store || null;
};

const appEnv = (store, action) => {
  if (action.type === 'APP_ENV') {
    return store;
  }

  return store || {};
};

const appRegion = (store, action) => {
  if (action.type === 'APP_REGION') {
    return store;
  }

  return store || {};
};

const requestUrl = (store, action) => {
  if (action.type === 'REQUEST_URL') {
    return store;
  }

  return store || {};
};

const Config = (store, action) => {
  if (action.type === 'SET_CONFIG_LANG') {
    return Object.assign(store, { language: action.language });
  }

  if (action.type === 'SET_CONFIG_REGION') {
    return Object.assign(store, { localeCode: action.region });
  }

  if (action.type === "SET_CONFIG_PATH") {
    return Object.assign(store, {path: action.path})
  }

  return store || {};
};

const sideNavData = (store, action) => {
  if (action.type === 'SET_SIDENAV_DATA') {
    return Object.assign(store, { sections: action.data });
  }

  return store || {};
};

const errorUrl = (store, action) => {
  if (action.type === 'ERROR_URL') {
    return store;
  }

  return store || null;
};

async function combiningReducers() {
  const { ConfiguratorReducers } = await Configurator
  const { CompareSectionReducers } = await TableCompareSection
  const { CommonSetLocationReducers } = await CommonSetLocation
  const { VehiclesCompareSectionReducers } = await VehicleComparisonTool
  const { VehiclesCompareSectionReducersV2 } = await VehicleComparisonToolV2
  const { FiftyFiftyReducers } = await FiftyFifty

  return combineReducers({
    routePathing,
    routeMapping,
    routePathMapping,
    GQLParams,
    Config,
    appRegion,
    appMode,
    appEnv,
    requestUrl,
    sideNavData,
    errorUrl,
    ...CommonSetLocationReducers,
    ...ConfiguratorReducers,
    ...CompareSectionReducers,
    ...VehiclesCompareSectionReducersV2,
    ...VehiclesCompareSectionReducers,
    ...FiftyFiftyReducers,
  })
}

export default combiningReducers()

import gql from "graphql-tag";

const AppConfigQuery = gql`
  query AppConfig($market: String!, $language: String) {
    AppConfig(market: $market, language: $language) {
      config {
        defaultLang
        measurementSystem
        currencyFormat
        languageDirectionMap {
          language
          direction
        }
        googleApiKey
        language
        distance {
          shortName
          longName
        }
        market
        dateFormat
        dateDisplayFormat
        defaultMapZoom
        initialRadius
        defaultLatitude
        defaultLongitude
        fontPaths {
          type
          paths
        }
      }
      routes {
        languages
        routes {
          vanityUrl
          vanityUrls
          redirectVanityURL
          resourcePath
          name
          useRouteParams
          childPages {
            vanityUrl
            vanityUrls
            redirectVanityURL
            resourcePath
            useRouteParams
            name
            childPages {
              vanityUrl
              vanityUrls
              redirectVanityURL
              resourcePath
              useRouteParams
              name
              childPages {
                vanityUrl
                vanityUrls
                redirectVanityURL
                resourcePath
                useRouteParams
                name
                childPages {
                  vanityUrl
                  vanityUrls
                  redirectVanityURL
                  resourcePath
                  name
                  useRouteParams
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default AppConfigQuery;

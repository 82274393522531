import React from 'react';
import { hydrate } from 'react-dom';
// import * as Sentry from '@sentry/react';
// import { notify } from "react-notify-toast";

import getPreloadedState from './utils/getPreloadedState';
import graphql from './graphql/index'; // ApolloProvider component, GraphQl client
import PromisifiedRedux from './redux/index.jsx'; // redux Provider component
import RouterProvider, { newRouterRenderEngine } from './router';

import { loadableReady } from '@loadable/component'

// Sentry.init({
//   dsn: 'https://b2b7f044488748f793e115ed6c03b4b4@o423295.ingest.sentry.io/5353322'
// });

//@ts-ignore
// require.ensure(
//   ["./sw-registration"],
//   //@ts-ignore
//   require => {
//     require("./sw-registration")(notify);
//   },
//   "sw-registration"
// );

(async () => {
  const {
    appMode,
    GQLParams,
    appRegion,
    appLang,
    appEnv,
    errorUrl,
  } = await getPreloadedState();
  const { ApolloProvider, graphqlClient } = graphql(GQLParams);
  
  const {
    routes,
    defaultLanguage,
    hasMulitLanguage,
    routeMappingObj,
    appConfiguration,
  } = await newRouterRenderEngine(graphqlClient, appRegion, appLang, appMode);

  const reduxProviderProps = {
    defaultLanguage,
    hasMulitLanguage,
    routeMappingObj,
    appConfiguration,
    appMode,
    appRegion,
    appEnv,
    errorUrl,
  };
  
  const ReduxProvider = await PromisifiedRedux()

  const clientApp = (
    <ReduxProvider
      {...reduxProviderProps}
    >
      <ApolloProvider>
        <RouterProvider>{routes}</RouterProvider>
      </ApolloProvider>
    </ReduxProvider>
  );

  loadableReady(() => {
    const root = document.getElementById('root');
    hydrate(clientApp, root);
  })

})();

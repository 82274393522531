// Not being referenced anywhere
// export const toggleCheck = () => {
//   return {
//     type: "TOGGLE_CHECK"
//   };
// };

// export const incNumber = () => {
//   return {
//     type: "INC_NUMBER"
//   };
// };

// export const decNumber = () => {
//   return {
//     type: "DEC_NUMBER"
//   };
// };

export const setConfigLanguage = language => {
  return {
    type: "SET_CONFIG_LANG",
    language
  };
};

export const setConfigRegion = region => {
  return {
    type: "SET_CONFIG_REGION",
    region
  };
};

export const setConfigPath = (path) => {
  return {
    type: "SET_CONFIG_PATH",
    path,
  };
};

export const setSideNavData = data => {
  return {
    type: "SET_SIDENAV_DATA",
    data
  };
};

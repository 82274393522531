import React from 'react';
import { ApolloProvider } from 'react-apollo';
import PropTypes from 'prop-types';

import graphqlClient from './client';
import graphqlEndpoint from './endpoint';

const defaultGQLParams = { endpoint: graphqlEndpoint() };

export default (GQLParams = defaultGQLParams) => {
  const preloadedApolloState = window.__APOLLO_STATE__;

  const client = graphqlClient.client(preloadedApolloState, GQLParams);

  const Provider = ({ children }) => {
    return <ApolloProvider client={client}>{children}</ApolloProvider>;
  };

  Provider.propTypes = {
    children: PropTypes.node.isRequired 
  }

  return { ApolloProvider: Provider, graphqlClient: client };
}

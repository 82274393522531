/* VENDOR SCRIPTS */
import PropTypes from "prop-types";
// import React, { Component, Fragment } from "react";
import React from "react";
// import { compose, graphql, Query } from "react-apollo";
import { connect } from "react-redux";
import Page from "./page";
/* END VENDOR SCRIPTS */

/* PAGE LEVEL TOOLS */
// import GTM from "../constants/gtm-scripts";
/* END PAGE LEVEL TOOLS */

// interface IAppWrapperProps {
//   router: Router;
//   theme: any;
//   config: any;
//   params: { lang: string };
//   location: Location;
// }

// interface IAppWrapperState {
//   appEnv: string;
//   appMode: string;
//   appRegion: string;
//   Config: any;
// }

function AppWrapper(props) {
// class AppWrapper extends Component {

  // render() {
    // const { router, theme, lang, config, appRegion, appMode, appEnv, location, ssrQueryParams, ...restProps } = this.props;
    const { router, theme, config, location, ssrQueryParams } = props;

    // let currentLang = this.props.params.lang;
    let currentLang = props.params.lang;

    if(!currentLang){
      currentLang = config.language;
    }

    currentLang = (currentLang.toUpperCase() !== currentLang && config.availableLanguages.indexOf(currentLang) > -1) ? currentLang : config.defaultLang;

    const {
      direction: languageDirection = "ltr"
    } = config.languageDirectionMap.find(el => el.language === currentLang);

    return (
      <>
        <Page
          router={router}
          theme={theme}
          currentLang={currentLang}
          languageDirection={languageDirection}
          fontPaths={config.fontPaths}
          // currentPath={this.props.location.pathname}
          currentPath={props.location.pathname}
          queryParams={{ ...ssrQueryParams, ...location.query }}
        />
      </>
    );
  // }
}

AppWrapper.propTypes = {
  data: PropTypes.object
};

AppWrapper.defaultProps = {
  data: {}
};

const mapStateToProps = state => {
  return {
    config: state.Config,
    appRegion: state.appRegion,
    appMode: state.appMode,
    appEnv: state.appEnv
  };
};

export default connect(
  mapStateToProps,
  dispatch => ({ dispatch })
)(AppWrapper);
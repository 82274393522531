import React from 'react';
import { Route, Redirect } from 'react-router';

import AppWrapper from '../components/app-wrapper';
import AppConfigQuery from '../graphql/constants/app_config-query';
import HardcodedRoutes from './constants/hardcoded-routes';

export default class RouterRenderEngine {
  constructor(client, previewResourcePath, appRegion, previewLang, appMode) {
    this.client = client;
    this.routeMapping = {};
    this.previewResourcePath = previewResourcePath || '';
    this.appRegion = appRegion;
    this.previewLang = previewLang;
    this.isPreview = appMode === 'preview';
    this.redirects = [];
    this.isMultiLanguage = null
  }

  get routeNodes() {
    return this.client
      .query({
        fetchPolicy: 'cache-first',
        query: AppConfigQuery,
        variables: {
          market: this.appRegion,
          language: this.previewLang,
        },
      })
      .then((result) => {
        if(process.env.NODE_ENV === 'development') {
          result.data.AppConfig.config.fontPaths = this.setLocalFontPaths(result.data.AppConfig.config.fontPaths);
        }

        const {routes} = result.data.AppConfig.routes;
        const {config} = result.data.AppConfig;
        const defaultLang = result.data.AppConfig.config.defaultLang
          ? result.data.AppConfig.config.defaultLang
          : result.data.AppConfig.routes.routes[0].name;

        this.setAppConfig(config);
        this.setDefaultLang(defaultLang);

        if (this.isPreview) {
          config.language = this.previewLang
            ? this.previewLang
            : this.previewResourcePath.split('/')[4];
        }

        config.availableLanguages = result.data.AppConfig.routes.languages;

        /* If a region does not have multiple languages, we need to remove the language from the URL */
        this.isMultiLanguage = this.hasMultipleLanguages(routes);

        const compiledFetchedRoutes = routes.map(async (route) => {
          const rootObj = {
            currentReactRouterPath: this.isMultiLanguage ? ['/:lang'] : [''],
            resolvedReactRouterPath: this.isMultiLanguage ? [route.name] : [''],
          };

          if (this.isMultiLanguage) {
            this.setRouteMapping(`/${route.name}/error`, {
              resourcePath: `${route.resourcePath}/error-pages/500`,
            });
          } else {
            this.setRouteMapping('/error', {
              resourcePath: `${route.resourcePath}/error-pages/500`,
            });
          }
          return this.makeRouteNodeTreeWithPromise(
            route,
            rootObj,
            true,
            this.isMultiLanguage,
            route.name,
          );
        });
        return Promise.all(compiledFetchedRoutes)
      })
      .then((compiledFetchedRoutes) => {
        const compiledErrorRoutes = [];

        if (this.isMultiLanguage) {
          compiledErrorRoutes.push(
            <Route
              key='*'
              path='/:lang/error'
              component={AppWrapper}
              title='Error'
              status={500}
             />,
          );
          compiledErrorRoutes.push(
            <Route
              key='*'
              path='/:lang/*'
              component={AppWrapper}
              title='Error'
              status={404}
             />,
          );
        } else {
          compiledErrorRoutes.push(
            <Route
              key='*'
              path='/error'
              component={AppWrapper}
              title='Error'
              status={500}
             />,
          );
          compiledErrorRoutes.push(
            <Route
              key='*'
              path='/*'
              component={AppWrapper}
              title='Error'
              status={404}
             />,
          );
        }

        const compiledHardcodedRoutes = HardcodedRoutes.map((route) => {
          const routeWithResource = route;
          if (routeWithResource.path === '/preview' && this.previewResourcePath !== '') {
            routeWithResource.resourcePath = this.previewResourcePath;
          }
          this.setRouteMapping(routeWithResource.path, routeWithResource);

          return (
            <Route
              key={routeWithResource.path}
              path={routeWithResource.path}
              component={AppWrapper}
              title={routeWithResource.path}
             />
          );
        });

        let compiledRoutes = compiledHardcodedRoutes.concat(compiledFetchedRoutes);

        compiledRoutes = this.redirects.concat(
          compiledRoutes.concat(compiledErrorRoutes),
        );

        return compiledRoutes;
      })
      .catch((error) => {
        console.log({ error });
      });
  }

  get routeMappingObj() {
    return this.routeMapping;
  }

  get defaultLanguage() {
    return this.defaultLang;
  }

  get hasMulitLanguage() {
    return this.numberOfLanguages > 1;
  }

  get appConfiguration() {
    return this.appConfig;
  }

  setAppConfig(config) {
    this.appConfig = config;
  }

  setDefaultLang(lang) {
    this.defaultLang = lang;
  }

  setRouteMapping(resolvedReactRouterPath, route) {
    this.routeMapping[resolvedReactRouterPath] = route.resourcePath;
  }

  setLocalFontPaths(fontPath) {
    fontPath.forEach(font => {
        font.paths = font.paths.map(path => `/local-fonts/${path.split('/')[path.split('/').length - 1]}`);
    });
    return fontPath;
  };

  logStubs() {
    return console.log(this.routes);
  }

  logRouteNodes() {
    return console.log(this.routeNodes);
  }

  static hasChildPages(route) {
    return route.childPages && route.childPages;
  }

  hasMultipleLanguages(routes) {
    this.numberOfLanguages = routes.length;
    return routes.length > 1;
  }

  async makeRouteNodeTreeWithPromise(route, routeObj, isRoot, isMultiLanguage, routeLang) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.makeRouteNodeTree(route, routeObj, isRoot, isMultiLanguage, routeLang))
      }, 0);
    })
  }

  makeRouteNodeTree(route, routeObj, isRoot, isMultiLanguage, routeLang) {
    const currentRouteObject = { ...routeObj};

    if (!isRoot) {
      currentRouteObject.currentReactRouterPath.push(route.name);
      currentRouteObject.resolvedReactRouterPath.push(route.name);
    }

    let path = currentRouteObject.currentReactRouterPath.join('/');
    const resolvedPath = currentRouteObject.resolvedReactRouterPath.join('/');

    if (path === '' && isRoot) {
      path = '/';
    }

    if (route.vanityUrls) {
      const fullPath = isMultiLanguage ? path.replace(':lang', routeLang) : path;
      route.vanityUrls.forEach((vanityUrl, index) => {
        this.redirects.push(<Redirect key={`multi-vanity-redir-${index}`} from={vanityUrl} to={fullPath} />);
      });
    } else if(route.vanityUrl){
      let fullPath = (isMultiLanguage) ? path.replace(":lang",routeLang) : path;
      this.redirects.push(<Redirect key="single-vanity-redir" from={route.vanityUrl} to={fullPath} />);
    }

    if (isRoot && !isMultiLanguage) {
      this.setRouteMapping('/', route);
    } else if (isMultiLanguage) {
      this.setRouteMapping(`/${resolvedPath}`, route);
      this.redirects.push(
        <Redirect key="multilang-redir" from={`/${resolvedPath}/`} to={`/${resolvedPath}`} />,
      );
    } else {
      this.setRouteMapping(resolvedPath, route);
      this.redirects.push(<Redirect key="non-multilang-redir" from={`${resolvedPath}/`} to={`${resolvedPath}`} />);
    }

    let pathWithParams = path;
    // Add route param if configured
    if (route.useRouteParams) {
      pathWithParams = `${path}/:routeParamOne`;
    }

    return (
      <Route key={path} path={pathWithParams} component={AppWrapper} title={path}>
        {RouterRenderEngine.hasChildPages(route) &&
          route.childPages.map((subroute) => {
            const parentRouteObj = {
              currentReactRouterPath: currentRouteObject.currentReactRouterPath.slice(0),
              resolvedReactRouterPath: currentRouteObject.resolvedReactRouterPath.slice(0,),
            };
            return this.makeRouteNodeTree(
              subroute,
              parentRouteObj,
              false,
              isMultiLanguage,
              routeLang,
            );
          })}
      </Route>
    );
  }
}

const hardcodedRoutes = [
  // {
  //   path: "/dev/stubs",
  //   resourcePath: "/content/mitsubishi-motors/language-masters/en"
  // },
  {
    path: '/preview',
    resourcePath: '',
  },
];

export default hardcodedRoutes;
